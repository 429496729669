import React from 'react'
import { useNavigate } from 'react-router-dom'

export const ViewerDashboard = () => {
  const navigate = useNavigate();
  return (
    <div className='col-10 mx-auto'>
        <div><span className='fs-4 fw-500'>ViewerDashboard</span></div>

        <div onClick={()=>{navigate('/viewer/ViewerCallStatus')}} className='mt-3 border-3 shadow-lg rounded-1 col-4 cursor-pointer'>
          <div className='py-3 d-flex justify-content-center'><span className='fw-500 fs-5'>View Call Details</span></div>
        </div>
    </div>
  )
}
