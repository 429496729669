import React, { useState, useEffect } from 'react';
import option1Img from "../../assets/hardly.png";
import option3Img from "../../assets/frequently.png";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import CompleteRaisec from './CompleteRaisec.jsx';
import ProgressBar from 'pages/course/ProgressBar';
import car from '../../assets/Raisec/f7_car-fill.svg';
import puzzle from '../../assets/Raisec/bi_puzzle-fill.svg';
import individual from '../../assets/Raisec/healthicons_man.svg';
import team from '../../assets/Raisec/ph_microsoft-teams-logo-fill.svg';
import goals from '../../assets/Raisec/mage_goals-fill.svg';
import build from '../../assets/Raisec/ant-design_build-filled.svg';
import art from '../../assets/Raisec/flat-color-icons_music.svg';
import teach from '../../assets/Raisec/mdi_teach.svg';
import follow from '../../assets/Raisec/ic_baseline-follow-the-signs.svg';
import influence from '../../assets/Raisec/noun-communication-6054926 1.svg';
import experiment from '../../assets/Raisec/noun-practical-5979935 1.svg';
import help from '../../assets/Raisec/mdi_help-outline.svg';
import animal from '../../assets/Raisec/solar_heart-bold.svg';
import office from '../../assets/Raisec/vaadin_office.svg';
import money from '../../assets/Raisec/10603249_42589 1.svg';
import creativeWriting from '../../assets/Raisec/fluent_draw-shape-20-regular.svg';
import science from '../../assets/Raisec/game-icons_materials-science.svg';
import leader from '../../assets/Raisec/noun-lead-4574738 1.svg';
import heal from '../../assets/Raisec/material-symbols-light_healing-outline.svg';
import creative from '../../assets/Raisec/noun-creative-person-1454273 1.svg';
import analyze from '../../assets/Raisec/noun-analyze-2087625 1.svg';
import attention from '../../assets/Raisec/icon-park-solid_attention.svg';
import records from '../../assets/Raisec/vaadin_records.svg';
import instrument from '../../assets/Raisec/noun-instruments-6274334 1.svg';
import business from '../../assets/Raisec/ic_twotone-add-business.svg';
import cook from '../../assets/Raisec/game-icons_cook.svg';
import acting from '../../assets/Raisec/noun-acting-1680796 1.svg';
import charts from '../../assets/Raisec/ep_trend-charts.svg';
import chat from '../../assets/Raisec/teenyicons_chat-typing-alt-outline.svg';
import math from '../../assets/Raisec/hugeicons_math.svg';
import speech from '../../assets/Raisec/mingcute_speech-fill.svg';
import hours8 from '../../assets/Raisec/i wouldnt mind working 8 hors a day in an office.png';
import drawing from '../../assets/Raisec/i like to draw.png';
import organize from '../../assets/Raisec/i like to organize things.png';
import figureOut from '../../assets/Raisec/i enjoy trying to figure out how things work.png';
import assemble from '../../assets/Raisec/i like putting things together or essembling things.png';
import filing from '../../assets/Raisec/i like to do filing or typing.png';
import culture from '../../assets/Raisec/i enjoy learning about other cultures.png';
import practical from '../../assets/Raisec/i am a practical person.png';
import lead from '../../assets/Raisec/i like to lead.png';
import outdoors from '../../assets/Raisec/i like working outdoors.png';
import helping from '../../assets/Raisec/i like helping people.png';


const Raisec = () => {
    const tempQuestion = [
        {
            "id": 7,
            "text": "I like to work on cars",
            "type": "r",
            "image": car,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 1,
            "createdAt": "2024-03-11T11:30:19.000Z",
            "updatedAt": "2024-03-11T11:30:19.000Z"
        },
        {
            "id": 8,
            "text": "I like to do puzzles",
            "type": "i",
            "image": puzzle,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 2,
            "createdAt": "2024-03-11T11:30:41.000Z",
            "updatedAt": "2024-03-11T11:30:41.000Z"
        },
        {
            "id": 9,
            "text": "I am good at working independently",
            "type": "a",
            "image": individual,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 3,
            "createdAt": "2024-03-11T11:30:58.000Z",
            "updatedAt": "2024-03-11T11:30:58.000Z"
        },
        {
            "id": 10,
            "text": "I like to work in teams",
            "type": "s",
            "image": team,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 4,
            "createdAt": "2024-03-11T11:31:23.000Z",
            "updatedAt": "2024-03-11T11:31:23.000Z"
        },
        {
            "id": 11,
            "text": "I am an ambitious person, I set goals for myself",
            "type": "e",
            "image": goals,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 5,
            "createdAt": "2024-03-11T11:33:04.000Z",
            "updatedAt": "2024-03-11T11:33:04.000Z"
        },
        {
            "id": 12,
            "text": "I like to organize things, (files, desks/offices)",
            "type": "c",
            "image": organize,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 6,
            "createdAt": "2024-03-11T11:33:40.000Z",
            "updatedAt": "2024-03-11T11:33:40.000Z"
        },
        {
            "id": 13,
            "text": "I like to build things",
            "type": "r",
            "image": build,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 7,
            "createdAt": "2024-03-11T11:33:52.000Z",
            "updatedAt": "2024-03-11T11:33:52.000Z"
        },
        {
            "id": 14,
            "text": "I like to read about art and music",
            "type": "a",
            "image": art,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 8,
            "createdAt": "2024-03-11T11:34:07.000Z",
            "updatedAt": "2024-03-11T11:34:19.000Z"
        },
        {
            "id": 15,
            "text": "I like to have clear instructions to follow",
            "type": "c",
            "image": follow,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 9,
            "createdAt": "2024-03-11T11:51:53.000Z",
            "updatedAt": "2024-03-11T11:51:53.000Z"
        },
        {
            "id": 16,
            "text": "I like to try to influence or persuade people",
            "type": "e",
            "image": influence,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 10,
            "createdAt": "2024-03-11T11:52:23.000Z",
            "updatedAt": "2024-03-11T11:52:23.000Z"
        },
        {
            "id": 17,
            "text": "I like to do experiments",
            "type": "i",
            "image": experiment,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 11,
            "createdAt": "2024-03-11T11:52:38.000Z",
            "updatedAt": "2024-03-11T11:52:38.000Z"
        },
        {
            "id": 18,
            "text": "I like to teach or train people",
            "type": "s",
            "image": teach,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 12,
            "createdAt": "2024-03-11T11:52:55.000Z",
            "updatedAt": "2024-03-11T11:53:04.000Z"
        },
        {
            "id": 19,
            "text": "I like trying to help people solve their problems",
            "type": "s",
            "image": help,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 13,
            "createdAt": "2024-03-11T11:53:26.000Z",
            "updatedAt": "2024-03-11T11:53:26.000Z"
        },
        {
            "id": 20,
            "text": "I like to take care of animals",
            "type": "r",
            "image": animal,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 14,
            "createdAt": "2024-03-11T11:53:46.000Z",
            "updatedAt": "2024-03-11T11:53:46.000Z"
        },
        {
            "id": 21,
            "text": "I wouldn’t mind working 8 hours per day in an office",
            "type": "c",
            "image": hours8,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 15,
            "createdAt": "2024-03-11T11:54:03.000Z",
            "updatedAt": "2024-03-11T11:54:03.000Z"
        },
        {
            "id": 22,
            "text": "I like selling things",
            "type": "e",
            "image": money,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 16,
            "createdAt": "2024-03-11T11:54:21.000Z",
            "updatedAt": "2024-03-11T11:54:21.000Z"
        },
        {
            "id": 23,
            "text": "I enjoy creative writing",
            "type": "a",
            "image": creativeWriting,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 17,
            "createdAt": "2024-03-11T11:54:37.000Z",
            "updatedAt": "2024-03-11T11:54:37.000Z"
        },
        {
            "id": 24,
            "text": "I enjoy science",
            "type": "i",
            "image": science,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 18,
            "createdAt": "2024-03-11T11:54:53.000Z",
            "updatedAt": "2024-03-11T11:54:53.000Z"
        },
        {
            "id": 25,
            "text": "I am quick to take on new responsibilities",
            "type": "e",
            "image": leader,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 19,
            "createdAt": "2024-03-11T11:55:15.000Z",
            "updatedAt": "2024-03-11T11:55:15.000Z"
        },
        {
            "id": 26,
            "text": "I am interested in healing people",
            "type": "s",
            "image": heal,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 20,
            "createdAt": "2024-03-11T11:56:44.000Z",
            "updatedAt": "2024-03-11T11:56:44.000Z"
        },
        {
            "id": 27,
            "text": "I enjoy trying to figure out how things work",
            "type": "i",
            "image": figureOut,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 21,
            "createdAt": "2024-03-11T11:57:01.000Z",
            "updatedAt": "2024-03-11T11:57:01.000Z"
        },
        {
            "id": 28,
            "text": "I like putting things together or assembling things",
            "type": "r",
            "image": assemble,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 22,
            "createdAt": "2024-03-11T11:57:30.000Z",
            "updatedAt": "2024-03-11T11:57:30.000Z"
        },
        {
            "id": 29,
            "text": "I am a creative person",
            "type": "a",
            "image": creative,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 23,
            "createdAt": "2024-03-11T11:58:40.000Z",
            "updatedAt": "2024-03-11T11:58:40.000Z"
        },
        {
            "id": 30,
            "text": "I pay attention to details",
            "type": "c",
            "image": attention,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 24,
            "createdAt": "2024-03-11T12:02:49.000Z",
            "updatedAt": "2024-03-11T12:03:24.000Z"
        },
        {
            "id": 31,
            "text": "I like to do filing or typing",
            "type": "c",
            "image": filing,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 25,
            "createdAt": "2024-03-11T12:04:06.000Z",
            "updatedAt": "2024-03-11T12:04:06.000Z"
        },
        {
            "id": 32,
            "text": "I like to analyze things (problems/ situations)",
            "type": "i",
            "image": analyze,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 26,
            "createdAt": "2024-03-11T12:04:26.000Z",
            "updatedAt": "2024-03-11T12:04:26.000Z"
        },
        {
            "id": 33,
            "text": "I like to play instruments or sing",
            "type": "a",
            "image": instrument,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 27,
            "createdAt": "2024-03-11T12:05:26.000Z",
            "updatedAt": "2024-03-11T12:05:26.000Z"
        },
        {
            "id": 34,
            "text": "I enjoy learning about other cultures",
            "type": "s",
            "image": culture,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 28,
            "createdAt": "2024-03-11T12:05:53.000Z",
            "updatedAt": "2024-03-11T12:05:53.000Z"
        },
        {
            "id": 35,
            "text": "I would like to start my own business",
            "type": "e",
            "image": business,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 29,
            "createdAt": "2024-03-11T12:06:11.000Z",
            "updatedAt": "2024-03-11T12:06:11.000Z"
        },
        {
            "id": 36,
            "text": "I like to cook",
            "type": "r",
            "image": cook,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 30,
            "createdAt": "2024-03-11T12:06:29.000Z",
            "updatedAt": "2024-03-11T12:06:29.000Z"
        },
        {
            "id": 37,
            "text": "I like acting in plays",
            "type": "a",
            "image": acting,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 31,
            "createdAt": "2024-03-11T12:06:53.000Z",
            "updatedAt": "2024-03-11T12:06:53.000Z"
        },
        {
            "id": 38,
            "text": "I am a practical person",
            "type": "r",
            "image": practical,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 32,
            "createdAt": "2024-03-11T12:07:45.000Z",
            "updatedAt": "2024-03-11T12:07:45.000Z"
        },
        {
            "id": 39,
            "text": "I like working with numbers or charts",
            "type": "i",
            "image": charts,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 33,
            "createdAt": "2024-03-11T12:08:01.000Z",
            "updatedAt": "2024-03-11T12:08:01.000Z"
        },
        {
            "id": 40,
            "text": "I like to get into discussions about issues",
            "type": "s",
            "image": chat,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 34,
            "createdAt": "2024-03-11T12:08:15.000Z",
            "updatedAt": "2024-03-11T12:08:15.000Z"
        },
        {
            "id": 41,
            "text": "I am good at keeping records of my work",
            "type": "c",
            "image": records,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 35,
            "createdAt": "2024-03-11T12:10:16.000Z",
            "updatedAt": "2024-03-11T12:10:16.000Z"
        },
        {
            "id": 42,
            "text": "I like to lead",
            "type": "e",
            "image": lead,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 36,
            "createdAt": "2024-03-11T12:11:30.000Z",
            "updatedAt": "2024-03-11T12:11:30.000Z"
        },
        {
            "id": 43,
            "text": "I like working outdoors",
            "type": "r",
            "image": outdoors,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 37,
            "createdAt": "2024-03-11T12:12:58.000Z",
            "updatedAt": "2024-03-11T12:12:58.000Z"
        },
        {
            "id": 44,
            "text": "I would like to work in an office",
            "type": "c",
            "image": office,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 38,
            "createdAt": "2024-03-11T12:13:12.000Z",
            "updatedAt": "2024-03-11T12:13:12.000Z"
        },
        {
            "id": 45,
            "text": "I’m good at math",
            "type": "i",
            "image": math,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 39,
            "createdAt": "2024-03-11T12:13:29.000Z",
            "updatedAt": "2024-03-11T12:13:29.000Z"
        },
        {
            "id": 46,
            "text": "I like helping people",
            "type": "s",
            "image": helping,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 40,
            "createdAt": "2024-03-11T12:13:49.000Z",
            "updatedAt": "2024-03-11T12:13:49.000Z"
        },
        {
            "id": 47,
            "text": "I like to draw",
            "type": "a",
            "image": drawing,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                },
            ],
            "order": 41,
            "createdAt": "2024-03-11T12:14:01.000Z",
            "updatedAt": "2024-03-11T12:14:01.000Z"
        },
        {
            "id": 48,
            "text": "I like to give speeches",
            "type": "e",
            "image": speech,
            "option": [
                {
                    "text": "No",
                    "score": 0
                },
                {
                    "text": "Yes",
                    "score": 1
                }
            ],
            "order": 42,
            "createdAt": "2024-03-11T12:14:27.000Z",
            "updatedAt": "2024-03-11T12:14:27.000Z"
        }
    ];

    const [currQuesIndex, setCurrQuesIndex] = useState(0);
    const [questions, setQuestions] = useState(tempQuestion);
    const [responses, setResponses] = useState(Array(tempQuestion.length).fill(null));
    const [progress, setProgress] = useState(0);
    const [completed, setCompleted] = useState(false);

    const optionImages = {
        3: [option1Img, option3Img, option3Img],
        2: [option1Img, option3Img]
    };



    useEffect(() => {
        setProgress((currQuesIndex / (questions.length - 1)) * 100);
    }, [currQuesIndex]);

    useEffect(() => {
        if (responses.every(response => response !== null)) {
            setCompleted(true);
        }
    }, [responses]);

    const handleNext = () => {
        if (currQuesIndex < questions.length - 1) {
            setCurrQuesIndex(currQuesIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currQuesIndex > 0) {
            setCurrQuesIndex(currQuesIndex - 1);
        }
    };

    const handleOptionClick = (optionIndex) => {
        const newResponses = [...responses];
        newResponses[currQuesIndex] = optionIndex;
        setResponses(newResponses);

        if (currQuesIndex === questions.length - 1) {
            const totalHighestMarks = calculateTotalHighestMarks();
            const percentage = calculatePercentage(newResponses, totalHighestMarks);
            console.log('Your percentage:', percentage);
        } else {
            handleNext();
        }
    };

    const calculateTotalHighestMarks = () => {
        let totalMarks = 0;
        questions.forEach(question => {
            let highestScore = 0;
            question.option.forEach(option => {
                if (option.score > highestScore) {
                    highestScore = option.score;
                }
            });
            totalMarks += highestScore;
        });
        return totalMarks;
    };

    const handleTryAgain = () => {
        setCompleted(false);
        setCurrQuesIndex(0);
        setResponses(Array(tempQuestion.length).fill(null));
        setProgress(0);
    }

    const calculatePercentage = (responses, totalHighestMarks) => {
        let totalScore = 0;
        responses.forEach((response, index) => {
            if (response !== null) {
                totalScore += questions[index].option[response].score;
            }
        });
        const percentage = (totalScore / totalHighestMarks) * 100;
        return percentage.toFixed(2);
    };

    const question = questions[currQuesIndex];
    const imagesToShow = optionImages[question.option.length] || [];
    return (
        <>
            {!completed ? (
                <div className="action-calculator" style={{ justifyContent: "space-evenly" }}>
                    <div className="progress-container">
                        <ProgressBar bgColor="green" progress={progress} />
                        <span>{progress.toFixed(0)}%</span>
                    </div>
                    <div className="questions-box">
                        <div
                            className="img-wrapper"


                            style={{
                                height: "10rem",
                                width: "10rem",
                                margin: "3rem 0 2rem 0",
                                overflow: "hidden",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        // style={{ height: "7rem", width: "7rem", margin: "3rem 0 2rem 0" }}
                        >
                            <img
                                src={tempQuestion[currQuesIndex].image}
                                alt={question.text}
                                style={{
                                    height: [40, 41].includes(tempQuestion[currQuesIndex].id) ? "85%" : "92%",

                                    objectFit: "cover",

                                    width: tempQuestion[currQuesIndex].id === 40 ? "85%" : "auto",
                                }}
                            />
                        </div>

                        <h3>{question.text}</h3>
                    </div>
                    <div className="options-container">
                        {currQuesIndex > 0 && (
                            <div className="prev-btn change-btn" onClick={handlePrev}>
                                <span><FaAngleLeft /></span>
                                Prev
                            </div>
                        )}
                        <div className="options-wrapper">
                            {question.option.map((optionObj, index) => (
                                <button
                                    key={index}
                                    className={`option ${responses[currQuesIndex] === index ? 'selected' : ''}`}
                                    onClick={() => handleOptionClick(index)}
                                >
                                    <div className="img-wrapper">
                                        <img src={imagesToShow[index] || option1Img} alt={optionObj.opt} />
                                    </div>
                                    <span>{optionObj.text}</span>
                                </button>
                            ))}
                        </div>
                        {currQuesIndex < questions.length - 1 && (
                            <div className="next-btn change-btn" onClick={handleNext}>
                                <span><FaAngleRight /></span>
                                Next
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <CompleteRaisec percentage={calculatePercentage(responses, calculateTotalHighestMarks())} handleTryAgain={handleTryAgain} />
            )}
        </>
    )
}

export default Raisec