import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
} from "@mui/material";
import * as XLSX from "xlsx";
import BulkDataFormat from "./BulkDataFormat";
import { Popup } from "layout/Popup";
import { Download } from "@mui/icons-material";
import { apiJsonAuth } from "api";
import { useGlobalContext } from "global/context";
import debounce from "lodash.debounce";
import YuvaLoader from "pages/Forum/components/Loader/YuvaLoader";

const createWorker = () => {
  return new Worker(new URL("../../../../lib/dataProcessorWorker.js", import.meta.url), {
    type: 'module',
  });
};

const BulkUploadModal = ({ role, open, onError, onSuccess }) => {
  const [fileData, setFileData] = useState([]);
  const [showEditor, setShowEditor] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useGlobalContext();

  // Debounced file upload handler
  const handleDebouncedFileUpload = debounce(async (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      readFile(file);
    }
  }, 300);

  // Read the file
  const readFile = (file) => {
    setIsLoading(true);
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        let jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        jsonData = jsonData.filter((row) => row.some((cell) => cell !== ""));
        if (jsonData.length === 0 || jsonData.length === 1) {
          setFileData([]);
          setIsLoading(false);
          onError(); // Close the modal
          Popup("error", "Data Not Found or Only One Row Available!");
          return;
        }

        if (jsonData.length > 7000) {
          setFileData([]);
          setIsLoading(false);
          onError(); // Close the modal
          Popup("error", "Data exceeds the limit of 7000 records!");
          return;
        }
        processFileData(jsonData);
      } catch (error) {
        onError()
        Popup("error", "Error reading file");
        setIsLoading(false);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const processFileData = (jsonData) => {
    setIsLoading(true);
    const worker = createWorker();

    const requiredHeaders = [
      "First_Name",
      "Last_Name",
      "Father_Name",
      "Contact",
      "Email",
      "Date_Of_Birth",
      "Gender",
    ];

    if (role === "student") {
      requiredHeaders.push("Stream", "Class");
    }

    // You can add role-based conditions here if necessary
    worker.postMessage({ jsonData, requiredHeaders });

    worker.onmessage = (event) => {
      const { users, error } = event.data;

      if (error) {
        onError();
        Popup("error", error);
        setIsLoading(false);
        return;
      }

      setFileData(users);
      // console.log("After File Parsing: ", users);
      setShowEditor(true);
      setIsLoading(false);
    };

    worker.onerror = (error) => {
      onError();
      console.log(error);
      Popup("error", "Error processing the file data");
      setIsLoading(false);
    };
  };


  const handleSaveChanges = async (updatedData) => {
    setIsLoading(true);
    console.log(" line 126Values to Save: ", updatedData);
    const uploadData = {
      correctData: updatedData.correctDataList || [],
      incorrectData: updatedData.incorrectDataList || [],
      role: role,
    };

    // Batch size to avoid payload overload
    const BATCH_SIZE = 250;

    // Function to split data into batches
    const splitIntoBatches = (dataList, batchSize) => {
      const batches = [];
      for (let i = 0; i < dataList.length; i += batchSize) {
        batches.push(dataList.slice(i, i + batchSize));
      }
      return batches;
    };

    const correctDataBatches = splitIntoBatches(uploadData.correctData, BATCH_SIZE);
    const incorrectDataBatches = splitIntoBatches(uploadData.incorrectData, BATCH_SIZE);

    const processBatch = async (dataBatch) => {
      const batchUploadData = {
        correctData: dataBatch.correctData || [],
        incorrectData: dataBatch.incorrectData || [],
        role: role,
      };

      try {
        const response = await apiJsonAuth.post(
          "api/v2/institute/studentBulkLogin",
          batchUploadData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    };

    try {
      let allDataSaved = [];
      let allDataFailed = [];
      let message = '';

      for (let i = 0; i < correctDataBatches.length || i < incorrectDataBatches.length; i++) {
        const correctBatch = correctDataBatches[i] || [];
        const incorrectBatch = incorrectDataBatches[i] || [];

        const batchResult = await processBatch({
          correctData: correctBatch,
          incorrectData: incorrectBatch,
        });

        allDataSaved = allDataSaved.concat(batchResult.dataSaved || []);
        allDataFailed = allDataFailed.concat(batchResult.dataFailed || []);
        message = batchResult.message;

      }

      Popup("success", message);
      onSuccess();
    } catch (error) {
      onError();
      Popup("error", "An error occurred while saving data.");
    } finally {
      setIsLoading(false);
      setShowEditor(false);
    }
  };

  return (
    isLoading ? <YuvaLoader show={isLoading} /> :
      <Dialog open={open} maxWidth="lg" fullWidth>
        <DialogTitle>Bulk Upload</DialogTitle>
        <DialogContent dividers>
          {!showEditor ? (
            <div className="text-center">
              <div className="text-center item-center text-danger">
                <span className="font-weight-bold">NOTE</span>
                <br />
                <span className="lh-1 fw-semibold">
                  1. Only the Provided Format is allowed for file upload <br />
                  2. Data limit is 5000 Users per Upload.{" "}
                </span>{" "}
                <br />
                <Tooltip title="Use Provided file format to upload the student details">
                  {role === "teacher" ? (
                    <Button
                      style={{ color: "white" }}
                      className="mt-2 rounded"
                      variant="contained"
                      color="success"
                      href="https://s3.ap-south-1.amazonaws.com/yuvamanthan.org/bulkRegister/Teacher_format.xlsx"
                      target="_blank"
                      startIcon={<Download />}
                    >
                      Download&nbsp;Template
                    </Button>
                  ) : (
                    <Button
                      style={{ color: "white" }}
                      className="mt-2 rounded"
                      variant="contained"
                      color="success"
                      href="https://s3.ap-south-1.amazonaws.com/yuvamanthan.org/bulkRegister/Student_format.xlsx"
                      target="_blank"
                      startIcon={<Download />}
                    >
                      Download&nbsp;Template
                    </Button>
                  )}
                </Tooltip>
              </div>
              <div className="file-upload-section">
                <div
                  className="rounded-4 pb-3 mt-4 mx-auto"
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    maxWidth: "500px",
                    border: "2px dashed grey",
                  }}
                >
                  <input
                    className="fade"
                    style={{
                      minHeight: 240,
                      width: "100%",
                      zIndex: 200,
                      position: "relative",
                    }}
                    type="file"
                    onClick={(e) => {
                      setFileData([]);
                      e.target.value = null;
                    }}
                    onChange={handleDebouncedFileUpload}
                    id="fileData"
                    disabled={isLoading}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 100,
                    }}
                    className="w-100 h-100"
                  >
                    <div className="h-100 w-100 bg-light">
                      <div className="py-4 text-center">
                        <h4>Drag and drop or Click To Add File.</h4>
                        <img
                          src="/images/upload-cloud-folder.png"
                          alt="upload"
                          width={200}
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <BulkDataFormat
              onClose={() => setShowEditor(false)}
              data={fileData}
              onSave={handleSaveChanges}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onError}>Close</Button>
        </DialogActions>
      </Dialog>
  );
};

export default BulkUploadModal;
