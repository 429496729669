import { apiJson } from 'api';
import { useGlobalContext } from 'global/context';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { Button } from '@mui/joy';

const SubAdminDashboard = () => {
  const {userData} = useGlobalContext();
  const [reminderData, setReminderData] = useState([]);
  const navigate = useNavigate();

  const getAllReminder =()=>{
    apiJson.get(`api/v2/admin/getAllreminder/${userData?.id}`)
    .then((res)=>{
      setReminderData(res?.data?.getReminderData)
    })
    .catch((err)=>{
      console.log(err.message);
    })
  }

  useEffect(()=>{
    getAllReminder()
  },[])

  return (
    <div className='col-10 mx-auto'>
    <div className='d-flex align-content-center justify-content-between'>
      <div>
        <span className='fs-4 fw-500'>Sub Admin Dashboard</span>
      </div>
      <div><Button className='px-2' onClick={()=>navigate('/subAdmin/SubAdminAssginData')}>Assigned State</Button></div>
    </div>

    <div className='row'>
        {reminderData?.map((ele)=>{
          return (
            <div key={ele?.id} onClick={()=> {navigate(`/subAdmin/SingleStateData/${ele?.schoolId}`)}} className='col-12 col-md-6 col-lg-4 rounded shadow-sm border-3 text-center mt-5 p-3 cursor-pointer'>
                                    <div className='mt-4'>
                                        <div><span className='fw-600 fs-6'>Institute Name: </span><span> {ele?.institution_name}</span></div>
                                    </div>
                                    {ele?.email && 
                                    <div className='mt-2'>
                                        <div><span className='fw-600 fs-6'>Email: </span><a href={`mailto:${ele.email}`}>{ele?.email}</a></div>
                                    </div>
                                    }
                                    <div className='mb-4 mt-2'>
                                        <div>{ele?.reminder_status ? <span className='fw-bold text-warning'>Reminder {ele?.reminder_status} <PendingActionsIcon/></span> : ""}</div>
                                    </div>
                                </div>
          )
        })}
      </div>
    </div>
  )
}

export default SubAdminDashboard