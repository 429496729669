// import { Box, Typography } from "@mui/material";
// import React from "react";

// const CareerContent = () => {
//   return (
//     <>
//       <Box>
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             gap: 2,
//             width: "100%",
//             overflow: "auto",
//             "@media(max-width:900px)": {
//               width: "100%",
//             },
//           }}
//         >
//           <Box
//             sx={{
//               fontSize: "1.25rem",
//               padding: "2rem",
//               "@media(max-width:900px)": {
//                 padding: "0.5rem",
//               },
//             }}
//           >
//             <Box
//               sx={{
//                 borderRadius: "1rem",
//                 backgroundColor: "#f0f0f0",
//                 width: "70rem",
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "flex-start",
//                 justifyContent: "flex-start",
//                 padding: "2rem",
//                 boxSizing: "border-box",
//                 "@media(max-width:900px)": {
//                   width: "100%",
//                 },
//               }}
//             >
//               <div className="lets-begin-your-first-day-cha-parent">
//                 <Typography
//                   component={"b"}
//                   sx={{
//                     fontSize: "1.3rem",
//                     fontWeight: 900,
//                     fontFamily: "cursive",
//                   }}
//                   className="preview"
//                 >
//                   Let's Begin your first Day Challenge..
//                 </Typography>
//                 <img className="tick-icon" alt="" src="/tick.svg" />
//               </div>
//               <div className="click-here-to-container">
//                 <Typography
//                   component={"span"}
//                   sx={{
//                     color: "blue",
//                     textDecoration: "underline",
//                     cursor: "pointer",
//                   }}
//                   className="click-here"
//                 >
//                   Click here
//                 </Typography>
//                 <Typography component={"span"} className="to-know-more">
//                   {" "}
//                   to know more.
//                 </Typography>
//               </div>
//             </Box>
//             <Box
//               sx={{
//                 padding: "2rem 2rem 2rem 1.5rem",
//                 boxShadow: "0 3.4px 46.5px rgba(0, 0, 0, 0.1)",
//                 borderRadius: "1rem",
//                 marginTop: "3rem",
//                 backgroundColor: "var(--color-ghostwhite)",
//                 border: "5px solid #2356fe",
//                 boxSizing: "border-box",
//                 width: "70rem",
//                 height: "35.713rem",
//                 overflow: "hidden",
//                 "@media(max-width:900px)": {
//                   width: "100%",
//                   overflow: "auto",
//                 },
//               }}
//             >
//               hello
//             </Box>
//           </Box>
//         </Box>
//       </Box>
//     </>
//   );
// };

// export default CareerContent;

import React from "react";
import Raisec from "./Raisec";
const CareerContent = ({ mainTopic }) => {
  let careerComponent = null;
  switch (mainTopic?.title) {
    case "Raisec":
      careerComponent = <Raisec />;
      break;
    default:
      careerComponent = null;
  }
  return <>{careerComponent}</>;
};

export default CareerContent;
