// import { Popup } from "layout/Popup";
// import CourseDescriptions from "pages/course/CourseDescriptions";
// import React, { useState } from "react";
// import { useParams } from "react-router-dom";
// import CareerPanel from "./CareerPanel";
// import CareerContent from "./CareerContent";

// const CareerPage = () => {
//   const [sidebarActive, setSidebarActive] = useState(false);
//   const [isGenerating, setIsGenerating] = useState(false);
//   const [progress, setProgress] = useState([]);
//   const [sectionCompleted, setSectionCompleted] = useState(0);
//   const [course, setCourse] = useState({});
//   const [series, setSeries] = useState([]);
//   const [viewIndex, setViewIndex] = useState(0);
//   const { courseId } = useParams();
//   const [certAvail, setCertAvail] = useState(false);
//   const [activeSec, setActiveSec] = useState();
//   const [courseCheck, setCourseCheck] = useState({});

//   const checkUserEnrolled = (data) => {
//     console.log(data);
//     setCourseCheck(data);
//   };

//   const setCurrentCourse = (id) => {
//     console.log(id);
//   };

//   const view = (data) => {
//     // switch (data?.vd_type) {
//     //   case 1:
//     //     return (
//     //       <VimeoPlayer
//     //         videoId={data.path}
//     //         viewIndex={viewIndex}
//     //         setViewIndex={setViewIndex}
//     //         handleDir={handleDir}
//     //         series={series}
//     //         postProgress={postProgress}
//     //         setActiveSec={setActiveSec}
//     //         setCertificateDownloadable={setCertificateDownloadable}
//     //       />
//     //     );
//     //     // return "video";
//     //     break;
//     //   case 2:
//     //     return (
//     //       <PdfPlayer
//     //         file={data.path}
//     //         viewIndex={viewIndex}
//     //         setViewIndex={setViewIndex}
//     //         series={series}
//     //         setActiveSec={setActiveSec}
//     //         postProgress={postProgress}
//     //         setCertificateDownloadable={setCertificateDownloadable}
//     //         handleDir={handleDir}
//     //       />
//     //     );
//     //     // return "pdf";
//     //     break;
//     //   case 3:
//     //     return (
//     //       <NewQuizComponent
//     //         file={data.path}
//     //         viewIndex={viewIndex}
//     //         setViewIndex={setViewIndex}
//     //         series={series}
//     //         progress={progress}
//     //         setActiveSec={setActiveSec}
//     //         postProgress={postProgress}
//     //         handleDir={handleDir}
//     //         setCertificateDownloadable={setCertificateDownloadable}
//     //       />
//     //     );
//     //     break;
//     //   default:
//     //     return <CourseFallBack />;
//     // }
//   };

//   //Generating the certificate
//   const generateCertificate = async () => {
//     setIsGenerating(true);
//     Popup("loading", "Please wait", "Applying for the Certificate ");
//     try {
//       //   let formdata = {
//       // courseId,
//       // studentId: userData.id,
//       //   };
//       //   const res = await apiAuth.post('/course/certificate', formdata, {
//       //     headers: {
//       //       Authorization: token,
//       //     },
//       //   });
//       //   if (res.status == 200) {
//       // console.log("OK 200!!!!");
//       // isGenerating(false);
//       // navigate(`/dashboard/certificate/${courseId}`);
//       // CheckCertificate()
//       // Popup('success', 'Applied Successfully', res?.data?.message);
//       //   }
//     } catch (err) {
//       if (err?.response?.status === 409) {
//         // console.log("NOT OK 409!!!!");
//         // navigate("/dashboard/certificate/" + courseId);
//         // Popup('warning', 'Already Applied', err?.response?.data?.message);
//       } else {
//         // console.log("NOT OK!!!!");
//         Popup("error", err?.response?.data?.message);
//       }
//     }
//   };
//   return (
//     <div
//       className="container-fluid p-relative p-0"
//       style={{ overflow: "hidden" }}
//     >
//       <div className="row g-0">
//         <div
//           className={`col-12 overflow-hidden col-lg-3 coursepanelColumn ${
//             sidebarActive ? "active" : ""
//           }`}
//           style={{ zIndex: 30 }}
//         >
//           <CareerPanel
//             sidebarActive={sidebarActive}
//             setSidebarActive={setSidebarActive}
//             generateCertificate={generateCertificate}
//             progress={progress}
//             sectionCompleted={sectionCompleted}
//             modules={course}
//             series={series}
//             isGenerating={isGenerating}
//             activeCourse={series[viewIndex]}
//             setCurrentCourse={setCurrentCourse}
//             courseId={courseId}
//             certAvail={certAvail}
//             activeSec={activeSec}
//             courseCheck={courseCheck?.status}
//           />
//         </div>

//         <button
//           className="btn d-lg-none coursePanelToggle btn-light border-dark rounded-2 bg-light py-2 px-1"
//           onClick={() => setSidebarActive(!sidebarActive)}
//         >
//           <img
//             src="https://cdn-icons-png.flaticon.com/512/5994/5994725.png"
//             alt=""
//             width={30}
//           />
//         </button>

//         <div className="col-12 col-lg-9 border-end">
//           <div className="container-fluid p-0">
//             <div
//               className="container-fluid p-0"
//               style={{
//                 position: "relative",
//                 overflow: "hidden",
//               }}
//             >
//               {courseCheck.status === "active"
//                 ? series?.length !== 0
//                   ? view(series[viewIndex])
//                   : "No course Found"
//                 : null}
//             </div>
//           </div>

//           {courseCheck.status === "active" ? (
//             <>
//               <CourseDescriptions
//                 course={course}
//                 activeCourse={series[viewIndex]}
//               />
//             </>
//           ) : (
//             <>
//               <CareerContent />
//             </>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CareerPage;

/* eslint-disable no-sparse-arrays */
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import CareerContent from "./CareerContent";
import ClubSideBar from "../ClubSideBar/ClubSideBar";

const CareerPage = () => {
  const initialOptions = [
    {
      title: "Raisec",
      topics: [
        {
          title: "Raisec",
          subtopics: [
            {
              title: "Raisec",
              tasks: [],
            },
          ],
        },
        {
          title: "Invention, Creation & Ownership",
          subtopics: [
            {
              title: "Invention, Creation & Ownership",
              tasks: [],
            },
            {
              title: "Invention, Creation & Ownership",
              tasks: [],
            },
            {
              title: "Invention, Creation & Ownership",
              tasks: [],
            },
            {
              title: "Invention, Creation & Ownership",
              tasks: [],
            },
            {
              title: "Invention, Creation & Ownership",
              tasks: [],
            },
            {
              title: "Invention, Creation & Ownership",
              tasks: [],
            },
            {
              title: "Invention, Creation & Ownership",
              tasks: [],
            },
          ],
        },
        {
          title: "Importance of IP",
          subtopics: [
            {
              title: "Importance of IP",
              tasks: [],
            },

            {
              title: "Importance of IP",
              tasks: [],
            },
          ],
        },
        {
          title: "IP in Everyday life",
          subtopics: [
            {
              title: "IP in Everyday life",
              tasks: [],
            },

            {
              title: "IP in Everyday life",
              tasks: [],
            },
          ],
        },
        {
          title: "Patents",
          subtopics: [
            {
              title: "Patents",
              tasks: [],
            },

            {
              title: "Patents",
              tasks: [],
            },
          ],
        },
        {
          title: "Designs",
          subtopics: [
            {
              title: "Designs",
              tasks: [],
            },

            {
              title: "Designs",
              tasks: [],
            },
          ],
        },
        {
          title: "Trademarks",
          subtopics: [
            {
              title: "Trademarks",
              tasks: [],
            },

            {
              title: "Trademarks",
              tasks: [],
            },
          ],
        },
        {
          title: "Copyrights",
          subtopics: [
            {
              title: "Copyrights",
              tasks: [],
            },

            {
              title: "Copyrights",
              tasks: [],
            },
          ],
        },
        {
          title: "Geographical Indications",
          subtopics: [
            {
              title: "Geographical Indications",
              tasks: [],
            },

            {
              title: "Geographical Indications",
              tasks: [],
            },
          ],
        },
        {
          title: "SICLD",
          subtopics: [
            {
              title: "SICLD",
              tasks: [],
            },

            {
              title: "SICLD",
              tasks: [],
            },
          ],
        },
        {
          title: "Case Study",
          subtopics: [
            {
              title: "Case Study",
              tasks: [],
            },

            {
              title: "Case Study",
              tasks: [],
            },
          ],
        },
        {
          title: "Famous Inventors and Inventions",
          subtopics: [
            {
              title: "Famous Inventors and Inventions",
              tasks: [],
            },

            {
              title: "Famous Inventors and Inventions",
              tasks: [],
            },
          ],
        },
      ],
    },
  ];
  const [options, setOptions] = useState(initialOptions);
  const [selectedSubTopic, setSelectedSubTopic] = useState(null);
  const [selectedMainTopic, setSelectedMainTopic] = useState({
    title: "Raisec",
    subtopics: [
      {
        title: "Raisec",
        tasks: [],
      },
    ],
  });
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1024);
  const [taskCompletionStatus, setTaskCompletionStatus] = useState(() => {
    const savedStatus = localStorage.getItem("Career_Tasks_Status");
    return savedStatus ? JSON.parse(savedStatus) : {};
  });
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  useEffect(() => {
    localStorage.setItem(
      "Career_Tasks_Status",
      JSON.stringify(taskCompletionStatus)
    );
  }, [taskCompletionStatus]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1024);
      if (window.innerWidth > 1024) {
        setIsSidebarVisible(true);
        setIsDetailsVisible(true);
        document.body.classList.remove("show-details");
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isSmallScreen) {
      if (selectedSubTopic || selectedMainTopic) {
        setIsSidebarVisible(false);
        setIsDetailsVisible(true);
        document.body.classList.add("show-details");
      } else {
        setIsSidebarVisible(true);
        setIsDetailsVisible(false);
        document.body.classList.remove("show-details");
      }
    } else {
      setIsSidebarVisible(true);
      setIsDetailsVisible(true);
      document.body.classList.remove("show-details");
    }
  }, [selectedSubTopic, selectedMainTopic, isSmallScreen]);

  const handleTaskCompletion = (subtopicTitle, taskTitle) => {
    setTaskCompletionStatus((prevStatus) => {
      const updatedStatus = { ...prevStatus };
      if (!updatedStatus[subtopicTitle]) {
        updatedStatus[subtopicTitle] = {};
      }
      updatedStatus[subtopicTitle][taskTitle] = true;
      return updatedStatus;
    });
  };

  const handleToggle = () => {
    setIsSidebarVisible((prev) => !prev);
    setIsDetailsVisible((prev) => !prev);
    if (isSmallScreen) {
      document.body.classList.toggle("show-details");
    }
  };
  return (
    <section
      className="dashboard-content-container"
      style={{ padding: "1rem" }}
    >
      {isSidebarVisible && (
        <div className="options-progress-panel">
          <ClubSideBar
            pageName="Careers"
            navigationData={options}
            setActiveTopic={setSelectedMainTopic}
            setActiveSubtopic={setSelectedSubTopic}
            taskCompletionStatus={taskCompletionStatus}
          />
        </div>
      )}
      {isDetailsVisible && (
        <section
          className={`options-progress-details-container ${
            isSmallScreen && !isSidebarVisible ? "hide" : ""
          }`}
        >
          {/* Guidelines for the Challenge */}
          <Box
            sx={{
              borderRadius: "1rem",
              backgroundColor: "#f0f0f0",
              // width: "70rem",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "2rem",
              boxSizing: "border-box",
              height: "fit-content",
              "@media(max-width:900px)": {
                // width: "100%",
              },
            }}
          >
            <div className="lets-begin-your-first-day-cha-parent">
              <Typography
                component={"b"}
                sx={{
                  fontSize: "1.3rem",
                  fontWeight: 900,
                  fontFamily: "cursive",
                }}
                className="preview"
              >
                {selectedSubTopic?.guideline
                  ? selectedSubTopic.guideline
                  : "Let's Begin your First Day Challenge"}
              </Typography>
              <img className="tick-icon" alt="" src="/tick.svg" />
            </div>
            <div className="click-here-to-container">
              <Typography
                component={"span"}
                sx={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                className="click-here"
              >
                Click here
              </Typography>
              <Typography component={"span"} className="to-know-more">
                {" "}
                to know more.
              </Typography>
            </div>
          </Box>

          {/* Title of the Challenge */}

          <Box>
            <Typography
              component={"h1"}
              sx={{
                fontSize: "2rem",
                fontWeight: 600,
                padding: "1rem 0rem 1rem 0rem",
              }}
            >
              {selectedMainTopic.title}
            </Typography>
          </Box>

          {(!isSmallScreen || !isSidebarVisible) && (
            <CareerContent mainTopic={selectedMainTopic} />
          )}
        </section>
      )}

      {isSmallScreen && !isSidebarVisible && (
        <button
          className="btn clubPanelToggle coursePanelToggle btn-light border-dark rounded-2 bg-light py-2 px-1"
          onClick={handleToggle}
        >
          <img
            src="https://cdn-icons-png.flaticon.com/512/5994/5994725.png"
            alt=""
            width={30}
          />
        </button>
      )}
    </section>
  );
};

export default CareerPage;
