import React, { useState, useEffect, memo, useMemo } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, TextField, Box, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { deromanize } from 'romans';

function extractRomanNumeral(classValue) {
    const romanMatch = classValue.match(/^M{0,4}(CM|CD|XC|XL|L?X{0,3})(IX|V?I{0,3})/i);
    if (romanMatch) {
        try {
            return deromanize(romanMatch[0]);
        } catch {
            return NaN;
        }
    }
    return NaN;
}

function validateField(key, value, role, classVal) {
    const regexes = {
        'contact': /^\d{10}$/,
        'email': /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        'first_name': /^[a-zA-Z]+(?:\s?[a-zA-Z]+|\.?\s?[a-zA-Z]+)+$/,
        'stream': /^[a-zA-Z]+(?:\s?[a-zA-Z]+|\.?\s?[a-zA-Z]+)+$/
    };

    if (value === undefined || value === null) {
        if (role === 'student' && ['class'].includes(key)) {
            return false;
        }
        return false;
    }

    let normalizedValue = String(value).trim();
    switch (key) {
        case 'dob':
            const isValidFormat = moment(normalizedValue, ['D-M-YYYY', 'DD-MM-YYYY'], true).isValid();
            if (!isValidFormat) return false;
            const birthDate = moment(normalizedValue, ['D-M-YYYY', 'DD-MM-YYYY']);
            if (birthDate.isAfter(moment())) return false;
            const age = moment().diff(birthDate, 'years');
            if (role === 'student' && age <= 9) return false;
            if (role === 'teacher' && age <= 19) return false;
            return true;

        case 'contact':
            normalizedValue = normalizedValue.replace(/^\+91[\s.-]*/g, '').replace(/\D/g, '');
            return regexes.contact.test(normalizedValue);

        case 'email':
            normalizedValue = normalizedValue.toLowerCase();
            return regexes.email.test(normalizedValue);

        case 'first_name':
        case 'last_name':
        case 'father_name':
            return regexes.first_name.test(normalizedValue);

        case 'class':
            let classNumber = parseInt(normalizedValue, 10);
            if (isNaN(classNumber)) {
                classNumber = extractRomanNumeral(normalizedValue);
            }
            return !isNaN(classNumber) && classNumber >= 1 && classNumber <= 12;

        case 'stream':
            if (role === 'student') {
                let classStr = String(classVal).trim()
                let classForSection = parseInt(classStr, 10);
                if (isNaN(classForSection)) {
                    classForSection = extractRomanNumeral(classStr);
                }

                if (classForSection >= 11 && classForSection <= 12) {
                    return normalizedValue !== '' && regexes.stream.test(normalizedValue);
                } else if (classForSection >= 1 && classForSection < 11) {
                    return normalizedValue === '' || regexes.stream.test(normalizedValue);
                }
            }
            return true;

        default:
            return true;
    }
}

const SpreadsheetView = memo(({ incorrectData, onUpdateUser }) => {
    const [editedData, setEditedData] = useState([]);
    const [validationState, setValidationState] = useState([]);
    const [selectedCell, setSelectedCell] = useState(null);
    const [page, setPage] = useState(0);
    const rowsPerPage = 10;

    const hiddenFields = ['id', 'instituteId', 'role'];

    const debouncedValidation = useMemo(() => _.debounce((index) => {
        handleBlur(index);
    }, 300), []);

    useEffect(() => {
        if (incorrectData && incorrectData.length > 0) {
            setEditedData(incorrectData);
            setValidationState(
                incorrectData.map(row => Object.keys(row).reduce((acc, key) => ({
                    ...acc,
                    [key]: validateField(key, row[key], row.role, row.class)
                }), {}))
            );
        }
    }, [incorrectData]);

    const handleChange = (globalIndex, key, value) => {
        const updatedData = [...editedData];
        updatedData[globalIndex][key] = value || '';
        setEditedData(updatedData);
        // debouncedValidation(globalIndex);
    };

    const handleBlur = async (globalIndex) => {
        const currentRow = editedData[globalIndex];
        if (!currentRow) return;

        const isRowValid = Object.keys(currentRow).every(key => {
            const value = currentRow[key] || '';
            return validateField(key, value, currentRow.role, currentRow.class);
        });

        if (isRowValid) {
            if (currentRow.contact) {
                currentRow.contact = currentRow.contact
                    .replace(/^\+91[-\s.]*/g, '') // Remove country code
                    .replace(/\D/g, '') // Remove non-digit characters
                    .slice(-10); // Ensure it’s the last 10 digits
            }
            let normalizedValue = String(currentRow.class).trim();
            let classNumber = parseInt(normalizedValue, 10);
            if (isNaN(classNumber)) {
                currentRow.class = extractRomanNumeral(normalizedValue);
            }
            await onUpdateUser(currentRow.id, currentRow);
            const updatedData = editedData.filter((_, i) => i !== globalIndex);
            setEditedData(updatedData);
            const updatedValidationState = validationState.filter((_, i) => i !== globalIndex);
            setValidationState(updatedValidationState);
        } else {
            setValidationState(prevState => {
                const updatedValidation = [...prevState];
                updatedValidation[globalIndex] = Object.keys(currentRow).reduce((acc, key) => ({
                    ...acc,
                    [key]: validateField(key, currentRow[key] || '', currentRow.role, currentRow.class)
                }), {});
                return updatedValidation;
            });
        }
    };

    const handleKeyDown = async (globalIndex, key, event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            await handleBlur(globalIndex);
            const nextCellIndex = globalIndex + 1;
            if (nextCellIndex < editedData.length) {
                setSelectedCell({ index: nextCellIndex, key: headers[0] });
            }
        }
    };

    const handleFocus = (globalIndex, key) => {
        setSelectedCell({ index: globalIndex, key });
    };

    const headers = useMemo(() => Object.keys(incorrectData[0] || {}).filter(header => !hiddenFields.includes(header)), [incorrectData]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Adjust paginated data to include global index references
    const paginatedData = useMemo(() => {
        const startIndex = page * rowsPerPage;
        return editedData.slice(startIndex, startIndex + rowsPerPage).map((row, localIndex) => ({
            ...row,
            globalIndex: startIndex + localIndex
        }));
    }, [editedData, page, rowsPerPage]);

    const totalPages = Math.ceil(editedData.length / rowsPerPage);

    return (
        <>
            <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
                <Table
                    sx={{
                        backgroundColor: '#ffffff',
                        borderCollapse: 'collapse',
                        width: '100%',
                        tableLayout: 'auto',
                        minWidth: '600px'
                    }}
                >
                    <TableHead>
                        <TableRow>
                            {headers.map((header) => (
                                <TableCell
                                    key={header}
                                    sx={{
                                        color: '#000000',
                                        backgroundColor: '#f1f1f1',
                                        borderColor: '#d9d9d9',
                                        textAlign: 'center',
                                        padding: '8px',
                                        fontWeight: 'bold',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        minWidth: '100px',
                                    }}
                                >
                                    {header.replace(/([A-Z])/g, ' $1').toUpperCase()}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData.map((row) => (
                            <TableRow key={row.id}>
                                {headers.map(header => (
                                    <TableCell
                                        key={header}
                                        sx={{
                                            color: '#000000',
                                            borderColor: '#d9d9d9',
                                            padding: '0',
                                            border: '1px solid #d9d9d9',
                                            minWidth: '100px',
                                        }}
                                    >
                                        <TextField
                                            variant="outlined"
                                            value={row[header] || ''}
                                            onChange={(e) => handleChange(row.globalIndex, header, e.target.value)}
                                            onBlur={() => debouncedValidation(row.globalIndex)}
                                            onFocus={() => handleFocus(row.globalIndex, header)}
                                            onKeyDown={(e) => handleKeyDown(row.globalIndex, header, e)}
                                            fullWidth
                                            inputProps={{
                                                style: {
                                                    padding: '8px',
                                                    height: '40px',
                                                    boxSizing: 'border-box',
                                                },
                                            }}
                                            sx={{
                                                backgroundColor: validationState[row.globalIndex][header] === false ? '#ffebee' : (selectedCell?.index === row.globalIndex && selectedCell?.key === header ? '#e3f2fd' : '#ffffff'),
                                                color: '#000000',
                                                borderColor: validationState[row.globalIndex][header] === false ? '#ef5350' : '#d9d9d9',
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: validationState[row.globalIndex][header] === false ? '#ef5350' : '#d9d9d9',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: validationState[row.globalIndex][header] === false ? '#ef5350' : '#a6a6a6',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#1976d2',
                                                        borderWidth: '2px',
                                                    },
                                                },
                                            }}
                                            disabled={validationState[row.globalIndex][header]}
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                <IconButton onClick={(e) => handleChangePage(e, page - 1)} disabled={page === 0}>
                    <ArrowLeft />
                </IconButton>
                <Typography>
                    Page {page + 1} of {totalPages}
                </Typography>
                <IconButton onClick={(e) => handleChangePage(e, page + 1)} disabled={page >= totalPages - 1}>
                    <ArrowRight />
                </IconButton>
            </Box>
        </>
    );
});

export default SpreadsheetView;
