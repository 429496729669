import React, { useState } from 'react';
import { Button, Grid, Box } from '@mui/material';
import Spreadsheet from 'react-spreadsheet';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { deromanize } from 'romans';


function extractRomanNumeral(classValue) {
  // Regex to extract only the Roman numeral part (e.g., "XII" from "XII B")
  const romanMatch = classValue.match(/^M{0,4}(CM|CD|XC|XL|L?X{0,3})(IX|V?I{0,3})/i);
  if (romanMatch) {
    try {
      return deromanize(romanMatch[0]);
    } catch {
      return NaN;
    }
  }
  return NaN;
}

function validateField(key, value, role, classVal) {
  const regexes = {
    'Contact': /^\d{10}$/,
    'Email': /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    'First_Name': /^[a-zA-Z]+(?:\s?[a-zA-Z]+|\.?\s?[a-zA-Z]+)+$/,
    'Stream': /^[a-zA-Z]+(?:\s?[a-zA-Z]+|\.?\s?[a-zA-Z]+)+$/
  };

  if (value === undefined || value === null) {
    if (role === 'student' && ['class'].includes(key)) {
      return false;
    }
    return false;
  }

  let normalizedValue = String(value).trim();
  switch (key) {
    case 'Date_Of_Birth':
      const isValidFormat = moment(normalizedValue, ['D-M-YYYY', 'DD-MM-YYYY'], true).isValid();
      if (!isValidFormat) return false;
      const birthDate = moment(normalizedValue, ['D-M-YYYY', 'DD-MM-YYYY']);
      if (birthDate.isAfter(moment())) return false;
      const age = moment().diff(birthDate, 'years');
      if (role === 'student' && age <= 9) return false;
      if (role === 'teacher' && age <= 19) return false;
      return true;

    case 'Contact':
      normalizedValue = normalizedValue.replace(/^\+91[\s.-]*/g, '').replace(/\D/g, '');
      return regexes.Contact.test(normalizedValue);

    case 'Email':
      normalizedValue = normalizedValue.toLowerCase();
      return regexes.Email.test(normalizedValue);

    case 'First_Name':
    case 'Last_Name':
    case 'Father_Name':
      return regexes.First_Name.test(normalizedValue);

    case 'Class':
      let classNumber = parseInt(normalizedValue, 10);
      if (isNaN(classNumber)) {
        classNumber = extractRomanNumeral(normalizedValue);
      }
      return !isNaN(classNumber) && classNumber >= 1 && classNumber <= 12;

    case 'Stream':
      if (role === 'student') {
        let classStr = String(classVal).trim()
        let classForSection = parseInt(classStr, 10);

        if (isNaN(classForSection)) {
          classForSection = extractRomanNumeral(classStr);
        }

        if (classForSection >= 11 && classForSection <= 12) {
          return normalizedValue !== '' && regexes.Stream.test(normalizedValue);
        } else if (classForSection >= 1 && classForSection < 11) {
          return normalizedValue === '' || regexes.Stream.test(normalizedValue);
        }
      }
      return true;

    default:
      return true;
  }
}

const BulkDataFormat = ({ onClose, data, onSave }) => {
  const { role } = useParams();
  const [spreadsheetData, setSpreadsheetData] = useState(() => {
    const headers = [
      'First_Name', 'Last_Name', 'Father_Name', 'Contact', 'Email',
      'Date_Of_Birth', 'Gender'
    ];

    if (role === 'student') {
      headers.push('Class', 'Stream');
    }

    if (data.length > 0) {
      const formattedData = [
        headers.map(header => ({ value: header, isHeader: true })),
        ...data.map(row =>
          headers.map(header => ({ value: row[header] || '', isHeader: false }))
        )
      ];
      return formattedData;
    }
    return [];
  });

  const handleSave = () => {
    const headers = spreadsheetData[0].map(header => header.value);
    const updatedData = spreadsheetData.slice(1).map(row => {
      let rowData = {};
      row.forEach((cell, index) => {
        rowData[headers[index]] = cell.value !== undefined && cell.value !== null ? cell.value : '';
      });
      return rowData;
    });

    const correctDataList = [];
    const incorrectDataList = [];

    updatedData.forEach(item => {
      let isValid = true;

      // Validate each item
      Object.keys(item).forEach(key => {
        if (!validateField(key, item[key], item['Class'])) {
          isValid = false;
        }
      });

      let classStr = String(item['Class']).trim()
      let classForSection = parseInt(classStr, 10);
      if (isNaN(classForSection)) {
        classForSection = extractRomanNumeral(classStr);
      }

      // Check if the student is in class 11-12 and Stream is empty
      if (role === 'student' && classForSection >= 11 && classForSection <= 12) {
        if (!item['Stream'] || item['Stream'].trim() === '') {
          isValid = false;
        }
      } else if (role === 'student' && classForSection >= 1 && classForSection < 11) {
        if (!item['Stream'] || item['Stream'].trim() === '') {
          item['Stream'] = 'NA';
        }
      }

      if (isValid) {
        // Normalize data if necessary
        if (item['Date_Of_Birth']) {
          item['Date_Of_Birth'] = moment(item['Date_Of_Birth'], ['D-M-YYYY', 'DD-MM-YYYY']).format('DD-MM-YYYY');
        }
        if (item['Contact']) {
          item['Contact'] = item['Contact']
            .replace(/^\+91[-\s.]*/g, '') // Remove country code
            .replace(/\D/g, '') // Remove non-digit characters
            .slice(-10); // Ensure it’s the last 10 digits
        }
        if (item['Email']) {
          item['Email'] = item['Email'].toLowerCase().trim();
        }
        if (item['Class']) {
          const classNumber = parseInt(item['Class'], 10);
          if (isNaN(classNumber)) {
            item['Class'] = extractRomanNumeral(item['Class']);
          }
        }
        if (item['First_Name']) {
          item['First_Name'] = item['First_Name'].trim();
        }
        if (item['Last_Name']) {
          item['Last_Name'] = item['Last_Name'].trim();
        }
        if (item['Father_Name']) {
          item['Father_Name'] = item['Father_Name'].trim();
        }
        if (item['Stream']) {
          item['Stream'] = item['Stream'].trim();
        }

        correctDataList.push(item);
      } else {
        incorrectDataList.push(item);
      }
    });

    onSave({ correctDataList, incorrectDataList });
  };


  const handleClose = () => {
    onClose();
  };

  return (
    <Box
      sx={{
        padding: '16px',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        maxWidth: '800px',
        margin: '0 auto'
      }}
    >
      <Grid container spacing={2} sx={{ paddingBottom: '16px' }}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '400px',
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
            }}
          >
            <Spreadsheet
              data={spreadsheetData}
              onChange={data => {
                setSpreadsheetData(data);
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '8px',
        }}
      >
        <Button
          variant="outlined"
          sx={{ color: 'grey', borderColor: 'grey' }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'green', color: 'white' }}
          onClick={handleSave}
        >
          Save Changes
        </Button>
      </Box>
    </Box>
  );
}

export default BulkDataFormat;
